<template>
    <div>
        <div class="title">新建读者
            <el-divider></el-divider>
            <el-form ref="addReaderFormRules" :rules="addReaderFormRules" :model="addReaderForm" label-width="80px">
                <div style=" display: grid;grid-template-columns: repeat(2, 1fr);margin-top: 20px;">
                    <div style="display: flex;flex-direction: column;">
                        <!-- <el-form-item label="账号：" label-width="130px" prop="account">
                            <el-input v-model="addReaderForm.account" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item label="密码：" label-width="130px" prop="password">
                            <el-input v-model="addReaderForm.password" placeholder="请输入密码"></el-input>
                        </el-form-item> -->
                        <el-form-item label="姓名：" label-width="130px" prop="username">
                            <el-input v-model="addReaderForm.username" placeholder="请输入姓名" ></el-input>
                        </el-form-item>
                        <div style="display: flex;">
                            <el-form-item label="卡号：" label-width="130px" prop="cardNo">
                                <el-input v-model="addReaderForm.cardNo" placeholder="请输入卡号,6-18阿拉伯数字"></el-input>
                            </el-form-item>
                            <div @click="randomIdCar(), clearValidate('addReaderFormRules')"
                                style="color: #2d8cf0;cursor: pointer;font-size: 14px;width: 70px;margin-left: 30px;margin-right: 15px;">
                                自动分配卡号
                            </div>
                        </div>
                        <div style="display: flex;">
                            <el-form-item label="联系地址:" style="width: 200%;" label-width="130px" prop="contactAddress">
                                <el-cascader size="large" :options="options" v-model="addReaderForm.selectedOptions"
                                    :props="{ checkStrictly: true }" @change="handleChange">
                                </el-cascader>
                            </el-form-item>
                            <el-input style="margin-left: 20px;" v-model="addReaderForm.contactAddress"
                                placeholder="请输入详细地址"></el-input>
                        </div>
                    </div>
                    <!-- <div>
                        
                        <el-form-item label="头像：" label-width="130px" style="width: 40;">
                            <el-upload class="upload-demo"  action="https://api.leshop.cloud//book/uploadImage" 
                                :limit="3" :file-list="fileList"
                                name="image"
                                :headers="headers"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess" 
                                :before-upload="beforeAvatarUpload"
                                >
                                <el-button size="mini" type="primary">点击上传（限2m）</el-button>
                            </el-upload>
                            <div v-if="this.photo.url != ''" style="background-color: aquamarine;width: 100px; height: 100px; margin: -48px 0px 0px 210px;"><el-image style="width: 100px; height: 100px" :src="photo.url" fit="fill"></el-image></div>
                        </el-form-item>
                        <el-form-item label="手机号：" label-width="130px">
                            <el-input v-model="addReaderForm.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>

                    </div> -->
                </div>
                <div style="display: flex;flex: 1;">
                    <el-form-item label="借阅规则:" label-width="130px" prop="ruleName">
                        <el-select v-model="addReaderForm.ruleName"  clearable placeholder="请选择借阅规则" @focus.once="getRuleList()">
                            <el-option 
                            v-for="item in opentionsRuleName" 
                            :key="item.value"
                            :label="item.label"
                            :value="item.label"
                            ></el-option>   
                        </el-select>
                    </el-form-item>
                    <el-form-item label="性别：">
                        
                        <el-select v-model="addReaderForm.sex"  clearable placeholder="请选择性别">
                            <el-option 
                            key="1"
                            label="男"
                            value="1"
                            ></el-option>
                            <el-option 
                            key="0"
                            label="女"
                            value="0"
                            ></el-option>    
                        </el-select>
                    </el-form-item>
                </div>
                <div style="display: flex;flex: 1;">
                    <el-form-item label="过期时间:" label-width="130px">
                        <el-radio-group v-model="addReaderForm.radio" @input="selectDefine">
                            <el-radio label="永不过期"></el-radio>
                            <el-radio label="自定义过期时间"></el-radio>
                        </el-radio-group>
                        <el-date-picker v-show="isShow == true" type="date" placeholder="请选择过期时间"
                          value-format="yyyy-MM-dd"  v-model="addReaderForm.expireDate" style="margin-left: 30px;"></el-date-picker>
                    </el-form-item>
                </div>
                <div style=" display: grid;grid-template-columns: repeat(2, 1fr);">
                    <div>
                        <el-form-item label="出生日期：" label-width="130px" prop="birthDate">
                            <el-date-picker type="date" placeholder="请选择出生日期" value-format="yyyy-MM-dd" v-model="addReaderForm.birthDate"
                                style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="身份证号：" label-width="130px" prop="idCard">
                            <el-input v-model="addReaderForm.idCard" placeholder="请输入身份证号"></el-input>
                        </el-form-item>
                        <el-form-item label="学校：" label-width="130px" prop="school">
                            <el-input v-model="addReaderForm.school" placeholder="请输入学校"></el-input>
                        </el-form-item>
                        <el-form-item label="年级：" label-width="130px" prop="grade">
                            <el-select v-model="addReaderForm.grade" placeholder="请选择年级">
                                <!-- <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option> -->
                                <el-option
                                v-for="item in optionsGrade"
                                :key="item.value"
                                :value="item.value"
                                :label="item.label"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班次：" label-width="130px" prop="clazz">
                            <el-input v-model="addReaderForm.clazz" placeholder="请输入班次"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="押金：" label-width="130px">
                            <el-input v-model="addReaderForm.deposit" placeholder="请输入阿拉伯数字金额，单位：元"></el-input>
                        </el-form-item>
                        <el-form-item label="民族：" label-width="130px">
                            <!-- 
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select> -->
                            <el-select v-model="addReaderForm.region" placeholder="请选择民族">
                                <el-option
                                    v-for="item in nationList"
                                    :key="item.id"
                                    :label="item.info"
                                    :value="item.value"
                                    ></el-option>
                            </el-select>        
                        </el-form-item>
                        <el-form-item label="紧急联系人姓名：" label-width="130px">
                            <el-input v-model="addReaderForm.contactName" placeholder="请输入紧急联系人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="紧急联系人电话：" label-width="130px">
                            <el-input v-model="addReaderForm.contactPhone" placeholder="请输入紧急联系人电话"></el-input>
                        </el-form-item>
                        <el-form-item label="学号：" label-width="130px">
                            <el-input v-model="addReaderForm.studentId" placeholder="请输入学号"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <el-form-item label="备注：" label-width="130px">
                    <el-input v-model="addReaderForm.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item style="margin-left: calc(25%);">
                    <el-button size="mini" style="margin-right: 120px;" @click="backToPage">返回</el-button>
                    <el-button type="success" size="mini" @click="confirmAddReader('addReaderFormRules')">确定新建</el-button>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import { addReader } from "@/api/reader.js"
import {getLendRecord } from "@/api/lendRecord.js"
import { uuid } from '@/utils/UUID';
export default {
    data() {
        return {
            //年级
            optionsGrade: [{
                value: "小班",
                label: "小班"
            }, {
                value: "中班",
                label: "中班"
            }, {
                value: "大班",
                label: "大班"
            }, {
                value: "一年级",
                label: "一年级"
            }, {
                value:"二年级",
                label: "二年级"
            }, {
                value: "三年级",
                label: "三年级"
            }, {
                value:"四年级",
                label: "四年级"
            }, {
                value:"五年级",
                label: "五年级"
            }, {
                value: "六年级",
                label: "六年级"
            }, {
                value: "七年级",
                label: "七年级"
            }, {
                value:"八年级",
                label: "八年级"
            }, {
                value: "九年级",
                label: "九年级"
            }, {
                value: "高一",
                label: "高一"
            }, {
                value:"高二",
                label: "高二"
            }, {
                value: "高三",
                label: "高三"
            }, {
                value: "毕业",
                label: "毕业"
            }, {
                value: "其他",
                label: "其他"
            }],

            //民族
            nationList: [
                {
                    id: 1,
                    info: '汉族',
                    value: '汉族'
                },
                {
                    id: 2,
                    info: '壮族',
                    value: '壮族'
                },
                {
                    id: 3,
                    info: '满族',
                    value: '满族'
                },
                {
                    id: 4,
                    info: '回族',
                    value: '回族'
                },
                {
                    id: 5,
                    info: '苗族',
                    value: '苗族'
                },
                {
                    id: 6,
                    info: '维吾尔族',
                    value: '维吾尔族'
                },
                {
                    id: 7,
                    info: '土家族',
                    value: '土家族'
                },
                {
                    id: 8,
                    info: '彝族',
                    value: '彝族'
                },
                {
                    id: 9,
                    info: '蒙古族',
                    value: 9
                },
                {
                    id: 10,
                    info: '藏族',
                    value: '藏族'
                },
                {
                    id: 11,
                    info: '布依族',
                    value: '布依族'
                },
                {
                    id: 12,
                    info: '侗族',
                    value: '侗族'
                },
                {
                    id: 13,
                    info: '瑶族',
                    value: '瑶族'
                },
                {
                    id: 14,
                    info: '朝鲜族',
                    value: '朝鲜族'
                },
                {
                    id: 15,
                    info: '白族',
                    value: '白族'
                },
                {
                    id: 16,
                    info: '哈尼族',
                    value: '哈尼族'
                },
                {
                    id: 17,
                    info: '哈萨克族',
                    value: '哈萨克族'
                },
                {
                    id: 18,
                    info: '黎族',
                    value: '黎族'
                },
                {
                    id: 19,
                    info: '傣族',
                    value: '傣族'
                },
                {
                    id: 20,
                    info: '畲族',
                    value: '畲族'
                },
                {
                    id: 21,
                    info: '傈僳族',
                    value: '傈僳族'
                },
                {
                    id: 22,
                    info: '仡佬族',
                    value: '仡佬族'
                },
                {
                    id: 23,
                    info: '东乡族',
                    value: '东乡族'
                },
                {
                    id: 24,
                    info: '高山族',
                    value: '高山族'
                },
                {
                    id: 25,
                    info: '拉祜族',
                    value: '拉祜族'
                },
                {
                    id: 26,
                    info: '水族',
                    value: '水族'
                },
                {
                    id: 27,
                    info: '佤族',
                    value: '佤族'
                },
                {
                    id: 28,
                    info: '纳西族',
                    value: '纳西族'
                },
                {
                    id: 29,
                    info: '羌族',
                    value: '羌族'
                },
                {
                    id: 30,
                    info: '土族',
                    value: '土族'
                },
                {
                    id: 31,
                    info: '仫佬族',
                    value: '仫佬族'
                },
                {
                    id: 32,
                    info: '锡伯族',
                    value: '锡伯族'
                },
                {
                    id: 33,
                    info: '柯尔克孜族',
                    value: '柯尔克孜族'
                },
                {
                    id: 34,
                    info: '达斡尔族',
                    value: '达斡尔族'
                },
                {
                    id: 35,
                    info: '景颇族',
                    value: '景颇族'
                },
                {
                    id: 36,
                    info: '毛南族',
                    value: '毛南族'
                },
                {
                    id: 37,
                    info: '撒拉族',
                    value: '撒拉族'
                },
                {
                    id: 38,
                    info: '布朗族',
                    value: '布朗族'
                },
                {
                    id: 39,
                    info: '塔吉克族',
                    value: '塔吉克族'
                },
                {
                    id: 40,
                    info: '阿昌族',
                    value: '阿昌族'
                },
                {
                    id: 41,
                    info: '普米族',
                    value: '普米族'
                },
                {
                    id: 42,
                    info: '鄂温克族',
                    value: '鄂温克族'
                },
                {
                    id: 43,
                    info: '怒族',
                    value: '怒族'
                },
                {
                    id: 44,
                    info: '京族',
                    value: '京族'
                },
                {
                    id: 45,
                    info: '基诺族',
                    value: '基诺族'
                },
                {
                    id: 46,
                    info: '德昂族',
                    value: '德昂族'
                },
                {
                    id: 47,
                    info: '保安族',
                    value: '保安族'
                },
                {
                    id: 48,
                    info: '俄罗斯族',
                    value: '俄罗斯族'
                },
                {
                    id: 49,
                    info: '裕固族',
                    value: '裕固族'
                },
                {
                    id: 50,
                    info: '乌孜别克族',
                    value: '乌孜别克族'
                },
                {
                    id: 51,
                    info: '门巴族',
                    value: '门巴族'
                },
                {
                    id: 52,
                    info: '鄂伦春族',
                    value: '鄂伦春族'
                },
                {
                    id: 53,
                    info: '独龙族',
                    value: '独龙族'
                },
                {
                    id: 54,
                    info: '塔塔尔族',
                    value: '塔塔尔族'
                },
                {
                    id: 55,
                    info: '赫哲族',
                    value: '赫哲族'
                },
                {
                    id: 56,
                    info: '珞巴族',
                    value: '珞巴族'
                }
                ],

            //借阅规则 暂时
            opentionsRuleName:[
               
            ],
            // 头像框 显示
            photo:{
                url:""
            },
            headers:{
                "Authorization":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJiaWQiOjEsImFpZCI6MSwiYWNjb3VudCI6ImxldHUxMjM0IiwidXNlcm5hbWUiOiLnqIvmoLnno4ogICJ9.AkDHUs_6NXASjfwNqc1G9pB-eCO5ZFiSoSQK_5WBLeU"
            },
            isShow: false,
            fileList: [],
            // 获得地址信息
            options: regionData,
            addReaderForm: {
                radio: "永不过期",
                username: "",
                cardNo: "",
                phone: "",
                contactAddress: "",
                ruleName: "",
                ruleId:"",
                sex: "1",
                expireDate: "",
                birthDate: "",
                idCard: "",
                school: "",
                clazz: "",
                remark: "",
                deposit: "",
                ethnic: "",
                contactName: "",
                contactPhone: "",
                studentId: "",
                password: "letu1234",
                account: "",

                selectedOptions: "",
                area: ""
            },
            addReaderFormRules: {
                username: [{ required: true, message: '请输入姓名', trigger: 'blur' },
                { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }],
                cardNo: [{ required: true, message: '请输入卡号', trigger: 'blur' },{min: 6, max: 32, message: '最小为6位', trigger: 'blur'}],
                ruleName: [{ required: true, message: '请选择借阅规则', trigger: 'change' }],
                expireDate: [{ type: 'date', required: true, message: '请选择过期日期', trigger: 'change' }],
                // birthDate: [{ required: true, message: '请输入生日', trigger: 'blur' }],
                school: [{ required: true, message: '请输入学校', trigger: 'blur' }],
                grade: [{ required: true, message: '请选择年级', trigger: 'change' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                account: [{ required: true, message: '请输入账号', trigger: 'blur' }]
            }
        };
    },

    mounted() {

    },

    methods: {
        //获取借阅规则
        getRuleList(){
            //console.log("获取借阅规则")
            getLendRecord().then((res)=>{
               // this.opentionsRuleName
              // console.log(res.data) 
               res.data.forEach(item=>{
                console.log(item)
                   this.opentionsRuleName.push({'value':item.id,'label':item.name})

               })
            })

        },
        //头像上传
        handleAvatarSuccess(res, file) {
        this.photo.url = URL.createObjectURL(file.raw);
        console.log(res)
       
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type ==='image/jpg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
        // 返回
        backToPage(){
            this.$router.go(-1)
        },
        // 确认添加
        confirmAddReader(addReaderFormRules) {
            // this.$refs[addReaderFormRules].validate((valid) => {
            //     if (valid) {
            //         alert('submit!');
            //     } else {
            //         console.log('error submit!!');
            //         return false;
            //     }
            // });
            this.$refs[addReaderFormRules].validate((valid,value)=>{
                if(valid){
                    this.addReaderForm.account = this.addReaderForm.cardNo
                    //opentionsRuleName
                    //this.addReaderForm.ruleId = this.opentionsRuleName.find(item=>item.value == this.addReaderForm.ruleName).id
                    //console.log(this.opentionsRuleName)
                    this.opentionsRuleName.forEach(item=>{
                        if(item.label == this.addReaderForm.ruleName){
                            this.addReaderForm.ruleId = item.value
                        }
                    })
                    let queryParams = { ... this.addReaderForm }
                   //console.log(queryParams);
                    addReader(queryParams).then(res => {
                        console.log(res)
                        if(res.code == 200){
                            alert("添加成功");
                            this.backToPage();
                        }else{
                            this.$message.error(res.data.message);
                        }
                    })
                }else{
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                }
            })


            
        },
        clearValidate (addReaderFormRules){
            if(this.addReaderForm.cardNo != ""){
               this.$refs[addReaderFormRules].clearValidate('cardNo')
            }
        },
        // 随机获得的卡号  防抖？？
        randomIdCar() {
            //this.$refs[addReaderFormRules].clearValidate('cardNo')
            let tempCardNo;

            this.addReaderForm.cardNo = uuid(9,9)
            this.addReaderForm.account = this.addReaderForm.cardNo
            // if (this.addReaderForm.cardNo == '') {
            //     tempCardNo = String(Math.random()+Date.parse(new Date())).substring(2, 11);
            //     this.addReaderForm.cardNo = tempCardNo;
            // } else {
            //     if (tempCardNo == this.addReaderForm.cardNo) {
            //         console.log("1", tempCardNo);
            //         console.log(this.addReaderForm.cardNo);
            //     }
            //     else {
            //         console.log("2", tempCardNo);
            //         console.log(this.addReaderForm.cardNo);
            //         this.addReaderForm.cardNo = String(Math.random()).substring(2, 11)
            //     }
            // }
            // console.log(this.addReaderForm.cardNo);
            // console.log(Date.parse(new Date())+"==========")

        },
        // 自定义时间
        selectDefine() {
            this.isShow = !this.isShow
        },
        handleChange(value) {
            console.log(value)
            this.getCodeToText(null, value)
        },
        getCodeToText(codeStr, codeArray) {
            console.log(codeStr);
            console.log(codeArray);
            // if (null === codeStr && null === codeArray) {
            //     return null;
            // } else if (null === codeArray) {
            //     codeArray = codeStr.split(",");
            // }
            // let area = "";
            // switch (codeArray.length) {
            //     case 1:
            //         console.log(CodeToText);
            //         area += CodeToText[codeArray[0]];
            //         break;
            //     case 2:
            //         area += CodeToText[codeArray[0]] + "/" + CodeToText[codeArray[1]];
            //         break;
            //     case 3:
            //         console.log(codeArray[0]);
            //         console.log(codeArray[1]);
            //         console.log(codeArray[2]);
            //         console.log(CodeToText);
            //         area +=
            //             CodeToText[codeArray[0]] +
            //             "/" +
            //             CodeToText[codeArray[1]] +
            //             "/" +
            //             CodeToText[codeArray[2]];
            //         break;
            //     default:
            //         break;
            // }
            // console.log(area)
            // this.addReaderForm.area = area
            // return area;
        }
    }
};
</script>

<style scoped>
.title {
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    /* border-bottom: 1px solid rgba(232, 228, 228, 0.801); */
}

.content {
    margin-top: 10px;
    padding: 5px;

    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}
</style>